import React, { useContext, useRef, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from './Layout'
import Nav, { updateNav } from './Nav'
import SEO from './SEO'
import { GlobalContext, ContextType } from './GlobalContextProvider'
import { PhotoType } from './Gallery'
import * as photoStyles from './Photo.module.css'

interface PhotoProps {
  photos: PhotoType[]
  photoTownLink: string
}

const Photo = (props: PhotoProps) => {
  const [fullScreen, setFullScreen] = useState<boolean>(false)
  const albumRef = useRef<HTMLDivElement>(null)
  const pageWrapperRef = useRef<HTMLElement>(null)
  const { nav } = useContext(GlobalContext) as ContextType
  if (nav.currentPage !== null) {
    updateNav(nav.currentPage.substr(0, 8) !== '/gallery')
  }
  useEffect(() => {
    if (window.location !== undefined) {
      const searchParam = new URLSearchParams(window.location.search)
      const photoID: number = parseInt(searchParam.get('id') ?? '0')
      const foundIndex = props.photos.findIndex(
        (elt) => elt.databaseId === photoID
      )
      const width = albumRef.current?.getBoundingClientRect().width
      albumRef.current?.scrollBy({
        top: 0,
        left: (width ?? 0) * foundIndex,
        behavior: 'auto',
      })
    }
  }, [])
  const slide = (direction: number) => {
    const width = albumRef.current?.getBoundingClientRect().width
    albumRef.current?.scrollBy({
      top: 0,
      left: (width ?? 0) * direction,
      behavior: 'smooth',
    })
    // ** animation code originally written and discarded left for future reference
    // const start = filmStripRef.current.getBoundingClientRect().x
    // const sliderKeyFrames = [
    //   {transform: `translateX(${start}px)`},
    //   {transform: `translateX(${start - width}px)`},
    // ]
    // filmStripRef.current.animate(sliderKeyFrames, { duration: 1000, fill: 'forwards' })
  }
  return (
    <Layout>
      <main ref={pageWrapperRef} className={photoStyles.pageWrapper}>
        <div className={photoStyles.album} ref={albumRef}>
          <div className={photoStyles.filmStrip}>
            {props.photos.map((photo, index) => {
              const photoClass: string = `${photoStyles.photoContainer} ${
                photo.fullSize.childImageSharp.gatsbyImageData.width >
                photo.fullSize.childImageSharp.gatsbyImageData.height
                  ? 'landscape'
                  : 'portrait'
              }`
              return (
                <div className={photoClass} key={index}>
                  <GatsbyImage
                    image={photo.fullSize.childImageSharp.gatsbyImageData}
                    imgStyle={{ objectFit: 'contain' }}
                    style={{ height: '100%' }}
                    alt={photo.caption}
                  />
                  <div
                    className={photoStyles.caption}
                    dangerouslySetInnerHTML={{ __html: photo.caption }}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <button
          className={`${photoStyles.right} ${photoStyles.fsButton}`}
          aria-label="Next picture"
          onClick={() => slide(1)}
        >
          &gt;
        </button>
        <button
          className={`${photoStyles.left} ${photoStyles.fsButton}`}
          aria-label="Previous picture"
          onClick={() => slide(-1)}
        >
          &lt;
        </button>
        <button
          className={`${photoStyles.fullScreen} ${
            fullScreen ? `${photoStyles.hide}` : `${photoStyles.show}`
          }`}
          aria-label="Enable Full Screen"
          onClick={() => {
            if (pageWrapperRef.current?.requestFullscreen) {
              pageWrapperRef.current.requestFullscreen()
            } else if (pageWrapperRef.current?.requestFullscreen) {
              pageWrapperRef.current.requestFullscreen()
            }
            setFullScreen(true)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrows-maximize"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#fff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="16 4 20 4 20 8" />
            <line x1="14" y1="10" x2="20" y2="4" />
            <polyline points="8 20 4 20 4 16" />
            <line x1="4" y1="20" x2="10" y2="14" />
            <polyline points="16 20 20 20 20 16" />
            <line x1="14" y1="14" x2="20" y2="20" />
            <polyline points="8 4 4 4 4 8" />
            <line x1="4" y1="4" x2="10" y2="10" />
          </svg>
        </button>
        <button
          className={`${photoStyles.fullScreen} ${
            fullScreen ? `${photoStyles.show}` : `${photoStyles.hide}`
          }`}
          aria-label="Exit Full Screen"
          onClick={() => {
            if (document !== undefined) {
              if (document.exitFullscreen) {
                document.exitFullscreen()
              } else if (document.exitFullscreen) {
                document.exitFullscreen()
              }
            }
            setFullScreen(false)
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrows-minimize"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#fff"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <polyline points="5 9 9 9 9 5" />
            <line x1="3" y1="3" x2="9" y2="9" />
            <polyline points="5 15 9 15 9 19" />
            <line x1="3" y1="21" x2="9" y2="15" />
            <polyline points="19 9 15 9 15 5" />
            <line x1="15" y1="9" x2="21" y2="3" />
            <polyline points="19 15 15 15 15 19" />
            <line x1="15" y1="15" x2="21" y2="21" />
          </svg>
        </button>
      </main>
      <Nav />
      <SEO
        title="Our European Tour - Photos"
        description="Photos from our European Tour"
        image="/bertie-beach-small.jpg"
        type="article"
      />
    </Layout>
  )
}

export default Photo
