// extracted by mini-css-extract-plugin
export var album = "Photo-module--album--2yqzd";
export var filmStrip = "Photo-module--filmStrip--3c8t1";
export var photoContainer = "Photo-module--photoContainer--l0qJT";
export var gatsbyImageWrapper = "Photo-module--gatsby-image-wrapper--Mli47";
export var caption = "Photo-module--caption--2Tlu5";
export var fsButton = "Photo-module--fsButton--2hV77";
export var left = "Photo-module--left--Se_z2";
export var right = "Photo-module--right--3HtTV";
export var fullScreen = "Photo-module--fullScreen--8XRQZ";
export var show = "Photo-module--show--398Bm";
export var hide = "Photo-module--hide--2iV2J";
export var pageWrapper = "Photo-module--pageWrapper--StgOK";