import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Photo from '../components/Photo'
import { PhotoType } from '../components/Gallery'

interface PhotoTemplateProps {
  data: {
    allWpMediaItem: {
      nodes: PhotoType[]
    }
  }
  pageContext: {
    id: number
    photoTownLink: string
  }
}

const PhotoTemplate: FunctionComponent<PhotoTemplateProps> = (props) => {
  const photos = props.data.allWpMediaItem.nodes.filter(
    (el) => el.mimeType === 'image/jpeg'
  )
  photos.sort((a, b) => {
    const timestampA = parseInt(a.mediaDetails.meta.createdTimestamp, 10)
    const timestampB = parseInt(b.mediaDetails.meta.createdTimestamp, 10)
    if (timestampA === timestampB) {
      return 0
    }
    if (timestampA > timestampB) {
      return 1
    }
    return -1
  })
  return (
    <Layout>
      <Photo photos={photos} photoTownLink={props.pageContext.photoTownLink} />
    </Layout>
  )
}

export default PhotoTemplate

export const query = graphql`
  query($id: Int) {
    allWpMediaItem(
      filter: { parentDatabaseId: { eq: $id }, mimeType: { eq: "image/jpeg" } }
    ) {
      nodes {
        databaseId
        caption
        mimeType
        mediaDetails {
          meta {
            createdTimestamp
          }
        }
        thumbnail: localFile {
          childImageSharp {
            gatsbyImageData(
              width: 300
              placeholder: BLURRED
              layout: CONSTRAINED
            )
          }
        }
        fullSize: localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
